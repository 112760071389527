<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <el-button type="primary" v-if="type=='-1' ||type==''" @click="launch();bEdit = false;">发起结算</el-button>
      <div class="flex-c-c">
      <el-select class="mr20" @change="selectTrigger(type)" v-model="type" clearable>
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
          {{ item.name }}
        </el-option>
      </el-select>
        <el-input
          v-model="companyName"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入企业或电话"
        ></el-input>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="getTableData"
          >查询</el-button
        >
      </div>
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"   
      @handleSelectionChange="handleSelectionChange"
    >

      <template v-slot:table>
        <el-table-column type="selection" width="55"/>
        <el-table-column type="index" align="center" label="序号" show-overflow-tooltip/>
        <el-table-column prop="order_id" align="center" label="订单id" />
        <el-table-column prop="companyId" align="center" label="企业ID" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="name" align="center" label="负责人" />
        <el-table-column prop="phone" align="center" label="联系方式"/>
        <el-table-column prop="payTimeA" align="center" label="付款时间" />
        <el-table-column prop="pay_money" align="center" label="付款金额" />
        <el-table-column prop="agentMoney" align="center" label="代理价格" />
        <el-table-column prop="duration" align="center" label="名片数量" />
        <el-table-column prop="settlement_money" align="center" label="结算金额" />
        <el-table-column prop="settlement" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status==null">
              待结算
            </span>
            <span v-else-if="scope.row.status==0">
              结算中
            </span>
             <span v-else>
              已结算
            </span>
          </template>
        </el-table-column>
        
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <customPopconfirm 
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="determine(order_id=scope.row.order_id)"
              icon="el-icon-info"
              icon-color="red"
              title="确定发起结算吗？">
              <el-button
                v-if="scope.row.status==null"
                class="ml20"
                style="color: #51cbcd"
                type="text"
                slot="reference"
                size="medium"
              >
              发起结算
              </el-button>
            </customPopconfirm>
            
          </template>
        </el-table-column>
      </template>
    </commonTable>
        <el-dialog
        title="系统提示"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="25%"
      >
      <h3>{{title}}</h3>
        <span slot="footer" class="dialog-footer">
          <el-button v-if="cancel" @click="centerDialogVisible = false;">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  addAgentSettlement,getAgent
} from "@/api/agentManage.js";
import {
  getDataTimeSec
} from "@/utils/index.js";
import customPopconfirm from "@/components/common/customPopconfirm";
export default {
  components: {
    commonTable,
    customPopconfirm,
  },
  data() {
    return {
      options: [
        { id: '-1', name: "待结算" },
        { id: '0', name: "结算中" },
        { id: '1', name: "已结算" },
      ],
      centerDialogVisible: false,
      companyName:'',
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      multipleSelection:[],
      type:'-1',
      title:'',
      cancel:true,
      order_id:''
    };
  },
  mounted() {
    this.getTableData();
  },
  filters:{
  },
  methods: {
     selectTrigger(val) {
       if(val!='-1'){
         this.type=val
       }
       this.getTableData();
        console.log(val)
        },
    launch(){
      this.centerDialogVisible=true
      if(this.multipleSelection.length==0){
         this.title='请先选择数据！'
         this.cancel=false
         return
       }
      this.title='是否提交所选？'
    },
    handleSelectionChange(val) {
      this.multipleSelection=[]
      this.multipleSelection = val;
      var str=[] 
      this.multipleSelection.forEach((item,index) => {
         str+=item.order_id+','
      });
      this.order_id=str
    },
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        settlement:this.type==-1?'':this.type,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyName: this.companyName
      };
      console.log(data)
      getAgent(data)
        .then((res) => {
          res.createTime = getDataTimeSec(res.createTime)
          res.endTime = getDataTimeSec(res.endTime)
          
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.tableData.forEach(item => {
            item.createTimeA = getDataTimeSec(item.createTime)
            item.endTimeA = getDataTimeSec(item.endTime)
            item.payTimeA = getDataTimeSec(item.pay_time)
          });
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //详情页
    detail_tap(companyId){

    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    //发起结算
     determine() {
       this.centerDialogVisible=false
       this.title=''
      if(!this.order_id){
        return    
       }
       console.log(this.order_id)
      this.loading = true;
      let data = {
        order_id:this.order_id,
      };
        addAgentSettlement(data)
        .then((res) => {
          this.getTableData();
          this.$message({
            message: "发起成功",
            type: "success",
          });
        })
        .catch((err) => {
         this.getTableData();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>