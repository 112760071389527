<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-input
          v-model="companyName"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入企业或电话"
        ></el-input>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="getTableData"
          >查询</el-button
        >
      </div>
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"   
    >

      <template v-slot:table>
         <!-- <el-table-column type="selection" width="55"/> -->
        <el-table-column type="index" align="center" label="序号" show-overflow-tooltip/>
        <el-table-column prop="companyId" align="center" label="企业ID" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="name" align="center" label="负责人" />
        <el-table-column prop="phone" align="center" label="联系方式"/>
        <!-- <el-table-column prop="currentNum" align="center" label="名片数上限" />
        <el-table-column prop="allBusinessCardCount" align="center" label="企业员工数" /> -->
        <el-table-column prop="createTimeA" align="center" label="开始时间" />
        <el-table-column prop="endTimeA" align="center" label="到期时长" />
        <el-table-column prop="endTime" align="center" label="状态">
          <template slot-scope="scope"
            ><span>
              {{scope.row | getStatus}}
            </span>
          </template>
        </el-table-column>
        
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: #51cbcd; cursor: pointer;margin-right:10px"
              @click="detail_tap(scope.row.companyId)"
            >
              详情
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectAgentCompanyList,getAgent
} from "@/api/agentManage.js";
import {
  getDataTimeSec
} from "@/utils/index.js";

export default {
  components: {
    commonTable,
  },
  data() {
    return {
      companyName:'',
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      multipleSelection:[],
      type:''
    };
  },
  mounted() {
    this.getTableData();
  },
  filters:{
    getStatus(companyCard) {
      if (companyCard.endTime) {
        let endTime = new Date(companyCard.endTime).getTime();
        let nowTime = new Date().getTime();
        if (endTime > nowTime) {
          return "正常" // 未到期返回''
        } else {
          return "已到期"
        }
      } else {
        return ""
      }
    },
  },
  methods: {
    //请求预约数据
    getTableData() {
      console.log(this.type)
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyName: this.companyName
      };
      selectAgentCompanyList(data)
        .then((res) => {
          res.createTime = getDataTimeSec(res.createTime)
          res.endTime = getDataTimeSec(res.endTime)
          
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.tableData.forEach(item => {
            item.createTimeA = getDataTimeSec(item.createTime)
            item.endTimeA = getDataTimeSec(item.endTime)
          });
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //详情页
    detail_tap(companyId){
      this.$router.push('/companyDetail?id=' + companyId)
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>